import React, { useEffect } from 'react';
import Button from '../components/Button';
import '../assets/css/Home.css';
import About from '../assets/image/about.png';
import ReviewSlide from '../components/ReviewSlide';
import BannerSlider from '../components/Banner-slider';

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  return (
    <>
      <section className='banner bg-banner bg-no-repeat bg-contain bg-bottom'>
        <div className='container'>
          <div className='grid lg:grid-cols-2 grid-cols-1 lg:gap-5 gap-10 pb-1'>
            <div>
              <h1>Connect Instantly, <br/> Share Effortlessly</h1>
              <p>Welcome to FlickNLinK, where we revolutionize the way you share your professional identity. Our digital profiles, QR codes, and NFC business cards make networking seamless and eco-friendly. Join us in the future of business communication.</p>
              <div className='flex justify-start items-stretch gap-5 md:mt-10 mt-5'>
                <Button link='/about' className='light-btn' name="Read More" />
                <Button link='/contact' className='light-btn' name="Contact Us" />
              </div>
            </div>
            <div className='banner-slider'>
              <BannerSlider/>
            </div>
          </div>
        </div>
      </section>
      <section className='about'>
        <div className='container'>
          <div className='grid lg:grid-cols-2 grid-cols-1 items-center lg:gap-5 gap-10'>
            <div>
              <h2>About My Business</h2>
              <p>FlickNLinK is dedicated to transforming the way professionals connect and share their information. We offer innovative digital profiles, QR codes, and NFC business cards that ensure you never miss an opportunity to make a lasting impression. Our solutions are designed to be user-friendly, secure, and environmentally conscious.</p>
              <Button link='/about' className='dark-btn' name='Read More' />
            </div>
            <div>
              <img src={About} alt='SlideFram' />
            </div>
          </div>
        </div>
      </section>
      <section className='services'>
        <div className='container'>
          <div className='grid md:grid-cols-2 grid-cols-1 items-center justify-between lg:gap-5 md:gap-10 gap-5'>
            <div>
              <h2>Awesome Card Features</h2>
            </div>
            <div className='md:mr-0 md:ml-auto'>
              <Button link='/feature' className='dark-btn' name='More Features' />
            </div>
          </div>
          <div className='services-card'>
            <div>
              <p className='card-heading'>Instant Sharing</p>
              <p>Share your contact details instantly with a simple tap or scan, making networking quick and effortless.</p>
            </div>
            <div>
              <p className='card-heading'>Eco-Friendly</p>
              <p>Reduce paper waste with our sustainable digital solutions, contributing to a greener and more environmentally friendly world</p>
            </div>
            <div>
              <p className='card-heading'>Customizable Profiles</p>
              <p>Personalize your digital profile to reflect your brand, ensuring a unique and professional presentation every time.</p>
            </div>
            <div>
              <p className='card-heading'>Secure Information</p>
              <p>Your data is protected with top-notch security measures, ensuring your information remains safe and confidential.</p>
            </div>
            <div>
              <p className='card-heading'>Easy Integration</p>
              <p>Seamlessly integrate with your existing digital tools, enhancing your workflow without any disruptions.</p>
            </div>
            <div>
              <p className='card-heading'>Analytics</p>
              <p>Track interactions and optimize your networking strategy with detailed analytics and insights.</p>
            </div>
          </div>
        </div>
      </section>
      <section className='interface'>
        <div className='container'>
          <div className='grid lg:grid-cols-2 grid-cols-1 items-center justify-between lg:gap-5 gap-10'>
            <div className='col-span-1'>
              <h2>User-Friendly Interface</h2>
              <p>Our platform is designed with simplicity in mind. Easily create, customize, and share your digital business cards with just a few clicks. The intuitive interface ensures a smooth experience for all users.</p>
              <ul>
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                  </svg>
                  Easy Navigation</li>
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                  </svg>
                  Quick Setup</li>
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                  </svg>
                  Real-Time Updates</li>
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                  </svg>
                  Interactive Design</li>
              </ul>
              {/* <Button className='dark-btn' name='All Services' /> */}
            </div>
            <div className='col-span-1 mr-0 ml-auto'>
              <img src={About} alt='SlideFram' />
            </div>
          </div>
        </div>
      </section>
      <section className='steps'>
        <div className='container'>
        <div className='grid md:grid-cols-2 grid-cols-1 items-center justify-between md:gap-5 md:gap-10 gap-5'>
            <div>
              <h2>3 Easy Steps</h2>
            </div>
            <div className='md:mr-0 md:ml-auto'>
              {/* <Button className='dark-btn' name='All Services' /> */}
            </div>
          </div>
          <div className='steps-card'>
            <div>
              <p className='card-heading'>Create Your Profile</p>
              <p>Sign up and create your personalized digital profile in minutes, making your professional information easily accessible.</p>
            </div>
            <div>
              <p className='card-heading'>Customize Your Card</p>
              <p>Add your contact details, social media links, and more to create a unique and professional digital business card.</p>
            </div>
            <div>
              <p className='card-heading'>Enjoy The Features</p>
              <p>Use QR codes or NFC technology to share your information instantly and effortlessly with just a tap or scan.</p>
            </div>
          </div>
        </div>
      </section>
      <ReviewSlide />
    </>
  )
}

export default Home