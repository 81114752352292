import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './page/Home';
import About from './page/About';
import Contact from './page/Contact';
import Header from './components/Header';
import Feature from './page/Feature';
import Pricing from './page/Pricing';
import Review from './page/Review';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div className="App">
        <Header/>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/feature" element={<Feature/>} />
          <Route path="/pricing" element={<Pricing/>} />
          <Route path="/review" element={<Review/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
