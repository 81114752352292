import React from 'react';
import SlideFram from '../assets/image/screenshot-frame.png';
import Slide1 from '../assets/image/slide-1.png';
import Slide2 from '../assets/image/slide-2.png';
import Slide3 from '../assets/image/slide-3.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function BannerSlider() {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
    };
  return (
    <div className='banner-slider-main'>
        <img src={SlideFram} alt='SlideFram' className='mx-auto main-slider' />
        <div className='slider-img'>
            <Slider {...settings}>
            <img src={Slide1} alt='SlideFram' className='mx-auto' />
            <img src={Slide2} alt='SlideFram' className='mx-auto' />
            <img src={Slide3} alt='SlideFram' className='mx-auto' />
            </Slider>
        </div>
        </div>
  )
}

export default BannerSlider;
