import React, { useEffect, useState } from 'react';
import Testimonial from '../assets/image/testimonial-3.jpg';
import '../assets/css/Home.css';
import axios from 'axios';
import Button from '../components/Button';
import BannerSlider from '../components/Banner-slider';

function Review() {
  const [ratting, setRatting] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios.get('https://digiadmin.acrodapt.com/api/ratting')
      .then(response => {
        setRatting(response.data);
      })
      .catch(err => {
      });
  },
    []);
  return (
    <>
      <section className='banner bg-banner bg-no-repeat bg-contain bg-bottom'>
        <div className='container'>
          <div className='grid lg:grid-cols-2 grid-cols-1 lg:gap-5 gap-10 pb-1'>
            <div>
              <h1>Review Connect</h1>
              <p>We greatly value the experiences and feedback of our clients. Their stories highlight the impact our innovative networking solutions have had on their professional lives. Explore their reviews and see how FlickNLinK is transforming business networking across various industries.</p>
              <div className='flex justify-start items-stretch gap-5 md:mt-10 mt-5'>
                <Button link='/contact' className='dark-btn' name="Contact Us" />
              </div>
            </div>
            <div className='banner-slider'>
              <BannerSlider />
            </div>
          </div>
        </div>
      </section>
      <section className='Testimonial'>
        <div className='container'>
          <h2>What Our Clients Say!</h2>
          <div className='grid lg:grid-cols-2 grid-cols-1 items-center lg:gap-5 gap-10 md:mt-20 mt-12'>
            {ratting.map((review, index) => (
              <ReviewCard key={index} data={review} />
            ))}
          </div>
        </div>
      </section>
    </>
  )
}


function ReviewCard(data) {
  var users = data.data.user_data;
  return (
    <>
      <div className='border-2 border-bgprimary rounded-xl p-5'>
        <div className='flex justify-start items-start gap-3 mb-4'>
          <img className='aspect-square border-2 w-16 border-bgsecondary rounded-xl overflow-hidden' src={Testimonial} alt='' />
          <div>
            {Object.entries(users).map(([key, value]) => (
              key === 'name' ? (
                <p className='font-bold mb-1'>{data.data.user_data.name}</p>
              ) : null
            ))}
          </div>
        </div>
        <div>
          <p>{data.data.comment}</p>
        </div>
      </div>
    </>
  )
}

export default Review;