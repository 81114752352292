import React, { useEffect, useState } from 'react'

import '../assets/css/Home.css';
import Button from '../components/Button';
import ReviewSlide from '../components/ReviewSlide';
import axios from 'axios';
import { Link } from 'react-router-dom';
import BannerSlider from '../components/Banner-slider';

function Pricing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [features, setFeatures] = useState([]);

  useEffect(() => {
    // Fetch the data from the Laravel API
    axios.get('https://digiadmin.acrodapt.com/api/pricing')
      .then(response => {
        setFeatures(response.data);
      })
      .catch(err => {
      });
  },
    []);


  return (
    <>
      <section className='banner bg-banner bg-no-repeat bg-contain bg-bottom'>
        <div className='container'>
          <div className='grid lg:grid-cols-2 grid-cols-1 lg:gap-5 gap-10 pb-1'>
            <div>
              <h1>Pricing Connect</h1>
              <p>At FlickNLinK, we believe in offering flexible pricing plans tailored to meet the diverse needs of professionals and businesses. Explore our transparent pricing options and choose the plan that best fits your networking goals, all while supporting a more eco-friendly future.</p>
              <div className='flex justify-start items-stretch gap-5 md:mt-10 mt-5'>
                <Button link='/contact' className='dark-btn' name="Contact Us" />
              </div>
            </div>
            <div className='banner-slider'>
              <BannerSlider/>
            </div>
          </div>
        </div>
      </section>
      <section className='PricePlans'>
        <div className='container'>
          <h2>Personal Plan</h2>
          <div className='grid lg:grid-cols-3 grid-cols-1 lg:gap-5 gap-10 md:mt-20 mt-12'>
            {features.map((feature, index) => (
              feature.type === 'Personal' ? (
                <PricingCard key={index} data={feature} />
              ) : (null)
            ))}
          </div>
        </div>
      </section>
      <section className='PricePlans'>
        <div className='container'>
          <h2>Business Plan</h2>
          <div className='grid lg:grid-cols-3 grid-cols-1 lg:gap-5 gap-10 md:mt-20 mt-12'>
            {features.map((feature, index) => (
              feature.type === 'Bussiness' ? (
                <PricingCard key={index} data={feature} />
              ) : (null)
            ))}
          </div>
        </div>
      </section>
      <ReviewSlide />
    </>
  )
}

function PricingCard(data) {
  const package_feature = JSON.parse(data.data.data);
  return (
    <>
      <div className='Price-plan'>
        <div className='mb-2'>
          <h6>{data.data.title}</h6>
          <p>{data.data.sub_title}</p>
          <p className='price'>₹ {data.data.price}</p>
        </div>
        <div className='mb-3'>
          <ul>
            {Object.entries(package_feature).map(([key, value]) => (
              value === 'on' ? (
                <li key={key}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg>
                  <strong>{key}</strong>
                </li>
              ) : value === 'off' ? (
                <li key={key}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
                  <strong>{key}</strong>
                </li>
              ) : null
            ))}
          </ul>
        </div>
        <div className='Price-btn'>
          <Link to='https://digiadmin.acrodapt.com/' target='_blank' className='light-btn' name='Get Start'>Get Start</Link>
        </div>
      </div>
    </>
  )
}

export default Pricing;