import React from 'react'
import { Link } from 'react-router-dom';

function Button({ link, title, name, className }) {
    return (
        <Link className={className} to={link} title={title}>
            {name}
        </Link>
    );
}

export default Button;