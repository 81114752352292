import React, { useEffect, useState } from 'react';
import '../assets/css/Home.css';
import ReviewSlide from '../components/ReviewSlide';
import axios from 'axios';
import Swal from "sweetalert2";
import BannerSlider from '../components/Banner-slider';

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    l_name: '',
    email: '',
    phone: '',
    comment: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name) newErrors.name = 'First name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email address is invalid';
    }
    if (!formData.phone) newErrors.phone = 'Phone number is required';
    else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = 'Phone number must be 10 digits';
    }
    if (!formData.comment) newErrors.comment = 'Comment is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      axios.post('https://digiadmin.acrodapt.com/api/contact',formData)
      .then(response => {
        if(response.data === 'success') {
          Swal.fire({
            title: "Success!",
            text: "Your enquiry send successfully",
            icon: "success",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              setFormData({
                name: '',
                l_name: '',
                email: '',
                phone: '',
                comment: '',
              });
            }
          });
        } else if(response.data === 'fail') {
          Swal.fire({
            title: "Fail!",
            text: "There are some issue",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch(err => {
      });
    }
  };

  return (
    <>
      <section className='banner bg-banner bg-no-repeat bg-contain bg-bottom'>
        <div className='container'>
          <div className='grid lg:grid-cols-2 grid-cols-1 lg:gap-5 gap-10 pb-1'>
            <div>
              <h1>Contact</h1>
              <p>We’re here to help! Whether you have questions about our digital profiles, NFC business cards, or need assistance with your account, the FlickNLinK team is ready to assist you. Feel free to reach out for any inquiries, support requests, or feedback.</p>
            </div>
            <div className='banner-slider'>
              <BannerSlider/>
            </div>
          </div>
        </div>
      </section>
      <section className='contact-form'>
        <div className='container'>
          <div className='bg-[#F0F6FF] md:w-[80%] w-full mx-auto px-5 py-8 rounded-xl border-2 border-bgprimary'>
            <h2>Contact Now</h2>
            <p>Feel free let's do now</p>
            <form className="w-full grid lg:grid-cols-2 grid-cols-1 lg:gap-5 gap-10" onSubmit={handleSubmit}>
              <div className="">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="border-2 border-bgprimary rounded-xl w-full md:py-4 py-2 px-4 outline-none focus:outline-none focus:border-2 focus:border-bgsecondary"
                  placeholder="Enter Your First Name"
                />
                {errors.name && <span className="text-red-500">{errors.name}</span>}
              </div>
              <div className="">
                <input
                  type="text"
                  name="l_name"
                  value={formData.l_name}
                  onChange={handleChange}
                  className="border-2 border-bgprimary rounded-xl w-full md:py-4 py-2 px-4 outline-none focus:outline-none focus:border-2 focus:border-bgsecondary"
                  placeholder="Enter Your Last Name"
                />
              </div>
              <div className="">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="border-2 border-bgprimary rounded-xl w-full md:py-4 py-2 px-4 outline-none focus:outline-none focus:border-2 focus:border-bgsecondary"
                  placeholder="Enter Your Email Address"
                />
                {errors.email && <span className="text-red-500">{errors.email}</span>}
              </div>
              <div className="">
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="border-2 border-bgprimary rounded-xl w-full md:py-4 py-2 px-4 outline-none focus:outline-none focus:border-2 focus:border-bgsecondary"
                  placeholder="Enter Your Phone Number"
                />
                {errors.phone && <span className="text-red-500">{errors.phone}</span>}
              </div>
              <div className="lg:col-span-2 col-span-1">
                <textarea
                  name="comment"
                  value={formData.comment}
                  onChange={handleChange}
                  className="border-2 border-bgprimary rounded-xl w-full md:py-4 py-2 px-4 outline-none focus:outline-none focus:border-2 focus:border-bgsecondary"
                  placeholder="Comment"
                ></textarea>
                {errors.comment && <span className="text-red-500">{errors.comment}</span>}
              </div>
              <div className="lg:col-span-2 col-span-1 text-center">
                <button type="submit" className='dark-btn' name='Send Message'>Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <ReviewSlide />
    </>
  );
}

export default Contact;
