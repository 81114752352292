import React, {useState, useEffect} from 'react'

import Testimonial from '../assets/image/testimonial-3.jpg'
import axios from 'axios';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


function ReviewSlide() {
    const [ratting, setRatting] = useState([]);

     useEffect(() => {
        axios.get('https://digiadmin.acrodapt.com/api/ratting')
        .then(response => {
            setRatting(response.data);
        })
        .catch(err => {
        });
    },
    []);

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true, // Pause on hover
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div>
            <section className='Testimonial'>
                <div className='container'>
                    <h2>What Our Clients Say!</h2>
                </div>
                    <div className='items-center md:mt-20 mt-12 max-w-full overflow-hidden px-2'>
                        <Slider {...settings}>
                            {ratting.map((review, index) => (
                                <ReviewCard key={index} data={review}/>
                            ))}
                            {ratting.map((review, index) => (
                                <ReviewCard key={index} data={review}/>
                            ))}
                        </Slider>
                    </div>
            </section>
        </div>
    )
}

function ReviewCard(data) {
    var users = data.data.user_data;
    return(
        <>
            <div className='border-2 border-bgprimary rounded-xl p-5'>
                <div className='flex justify-start items-start gap-3 mb-4'>
                    <img className='aspect-square border-2 w-16 border-bgsecondary rounded-xl overflow-hidden' src={Testimonial} alt='' />
                    <div>
                    {Object.entries(users).map(([key, value]) => (
                        key === 'name' ? (
                            <p className='font-bold mb-1'>{data.data.user_data.name}</p>
                        ) : null
                    ))}
                    </div>
                </div>
                <div>
                    <p>{data.data.comment}</p>
                </div>
            </div>
        </>
    )
}

export default ReviewSlide;
