import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';

import '../components/Header.css';

function Header() {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const location = useLocation();
    const { pathname } = location;

    const closeDropdown = (event) => {
        if (!event.target.closest('.dropdown')) {
            setIsDropdownOpen(false);
        }
    };

    React.useEffect(() => {
        if (isDropdownOpen) {
            document.addEventListener('click', closeDropdown);
        } else {
            document.removeEventListener('click', closeDropdown);
        }

        return () => {
            document.removeEventListener('click', closeDropdown);
        };
    }, [isDropdownOpen]);
    return (
        <header>
            <nav className='px-10'>
                <div className="button-logo">
                    <Link to='/' className="logo">
                        <h1>FlickNLinK</h1>
                    </Link>
                    <button
                        id='nav-icon'
                        className={`md:hidden ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${isNavOpen ? 'toggle-open' : 'toggle-hidden'}`}
                        onClick={() => setIsNavOpen(!isNavOpen)}
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
                <div className={`menu-links ${isNavOpen ? 'menu-open' : 'menu-hidden'}`}>
                    <ul className="header-menu">
                        <li>
                            <Link
                                to="/"
                                className={`${pathname === '/' ? 'active-menu' : ''}`}
                                onClick={() => setIsNavOpen(!isNavOpen)}
                            >
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/about"
                                className={`${pathname === '/about' ? 'active-menu' : ''}`}
                                onClick={() => setIsNavOpen(!isNavOpen)}
                            >
                                About us
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/feature"
                                className={`${pathname.startsWith('/feature') ? 'active-menu' : ''}`}
                                onClick={() => setIsNavOpen(!isNavOpen)}
                            >
                                Feature
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/pricing"
                                className={`${pathname === '/pricing' ? 'active-menu' : ''}`}
                                onClick={() => setIsNavOpen(!isNavOpen)}
                            >
                                Pricing
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/review"
                                className={`${pathname === '/review' ? 'active-menu' : ''}`}
                                onClick={() => setIsNavOpen(!isNavOpen)}
                            >
                                Review
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/contact"
                                className={`${pathname === '/contact' ? 'active-menu' : ''}`}
                                onClick={() => setIsNavOpen(!isNavOpen)}
                            >
                                Contact
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    )
}

export default Header;